var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titulo-seccion',[_c('template',{slot:"area-rutas"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Inicio")]),_vm._v(" / "),_c('span',{staticClass:"active"},[_vm._v("Publicaciones")])],1),_c('template',{slot:"area-titulo"},[_c('h3',{staticClass:"text-center"},[_vm._v("Publicaciones")])])],2),_c('div',{staticClass:"container my-4",attrs:{"id":"content-page"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.buscar),expression:"buscar"}],staticClass:"mb-2 form-control",attrs:{"type":"search","placeholder":"Buscar"},domProps:{"value":(_vm.buscar)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.buscarLibro()},"input":function($event){if($event.target.composing)return;_vm.buscar=$event.target.value}}})]),_c('div',{staticClass:"gap-2 col-md-6 d-grid d-md-flex justify-content-md-end"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-2'),expression:"'collapse-2'"}],staticClass:"mb-2 btn btn-fiscalia",style:({
            '--background-color-btn': _vm.config.disenio.datos.color_primario,
          }),attrs:{"type":"button"}},[_c('svg',{staticClass:"bi bi-funnel",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"}})])])])]),_c('div',{staticClass:"my-4 row"},[_c('div',{staticClass:"col-md-4 d-none d-md-block"},[_c('b-card',{staticClass:"border"},[_c('b-form-group',{attrs:{"label":"Categorías"}},[_c('b-form-radio-group',{style:({
                '--background-color-secondary':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"state":_vm.state,"options":_vm.categoriaOpciones,"name":"radios-stacked","plain":"","stacked":""},model:{value:(_vm.categoria_id),callback:function ($$v) {_vm.categoria_id=$$v},expression:"categoria_id"}})],1),_c('b-form-group',{attrs:{"label":"Tipo"}},[_c('b-form-radio-group',{style:({
                '--background-color-secondary':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"options":_vm.tipoOpciones,"name":"radios-stacked1","plain":"","stacked":""},model:{value:(_vm.tipo_id),callback:function ($$v) {_vm.tipo_id=$$v},expression:"tipo_id"}})],1),_c('b-form-group',{attrs:{"label":"Fecha"}},[_c('b-form-radio-group',{style:({
                '--background-color-secondary':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"options":_vm.fechaOpciones,"name":"radios-stacked2","plain":"","stacked":""},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}})],1)],1)],1),_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('div',[_c('b-collapse',{attrs:{"id":"collapse-2"}},[_c('b-card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label":"Categorias"}},[_c('b-form-select',{attrs:{"options":_vm.categoriaOpciones},model:{value:(_vm.categoria_id),callback:function ($$v) {_vm.categoria_id=$$v},expression:"categoria_id"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label":"Tipo"}},[_c('b-form-select',{attrs:{"options":_vm.tipoOpciones},model:{value:(_vm.tipo_id),callback:function ($$v) {_vm.tipo_id=$$v},expression:"tipo_id"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label":"Fecha"}},[_c('b-form-select',{attrs:{"options":_vm.fechaOpciones},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}})],1)],1)])])],1)],1),_c('b-card',{staticClass:"border",attrs:{"no-body":""}},[_c('div',{staticClass:"media-list media-bordered"},_vm._l((_vm.items),function(item,index){return _c('b-media',{key:index,scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('div',{staticClass:"border rounded box-imagen box-imagen1 cursor"},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[(
                        item.miniatura != '' &&
                        item.miniatura != 'null' &&
                        item.miniatura
                      )?_c('b-img-lazy',{staticClass:"rounded cursor",attrs:{"src":item.miniatura,"center":"","fluid":"","thumbnail":"","alt":"Card image cap"}}):_c('b-img-lazy',{staticClass:"rounded cursor",attrs:{"src":"images/pdf.png","center":"","fluid":"","thumbnail":"","alt":"Card image cap"}})],1)])]},proxy:true}],null,true)},[_c('div',[_c('h6',{staticClass:"item-name"},[_vm._v(" "+_vm._s(item.titulo)+" ")]),_c('p',{staticClass:"mb-0 item-by"},[_c('span',{staticClass:"text-muted"},[_vm._v("Autor: ")]),_c('span',[_vm._v(_vm._s(item.autor))])]),_c('p',{staticClass:"item-by"},[_c('span',{staticClass:"text-muted"},[_vm._v("Publicado: ")]),_c('span',[_vm._v(_vm._s(item.publicado))])]),_c('b-card-text',{staticClass:"item-descripcion",domProps:{"innerHTML":_vm._s(item.descripcion)}}),_c('b-card-text',{staticClass:"mb-0 item-descripcion"},[_c('span',{staticClass:"text-muted"},[_vm._v("Categoría: ")]),_vm._v(_vm._s(item.categoria.nombre)+" ")]),_c('b-card-text',{staticClass:"mb-0 item-descripcion"},[_c('span',{staticClass:"text-muted"},[_vm._v("Tipo: ")]),_vm._v(_vm._s(item.tipo.nombre)+" ")]),_c('b-card-text',{staticClass:"mb-0 item-descripcion"},[_c('span',{staticClass:"text-muted"},[_vm._v("Etiquetas: ")]),_vm._l((item.etiquetas),function(itiqueta,index){return _c('span',{key:index},[_vm._v(" #"+_vm._s(itiqueta.nombre)+" ")])})],2)],1)])}),1)])],1)]),_c('div',{staticClass:"mt-5 mb-5 text-center row",attrs:{"id":"navegacion"}},[_c('b-pagination-nav',{staticClass:"my-0",style:({
          '--background-color-secondary':
            _vm.config.disenio.datos.color_secundario,
          '--background-color-tertiary': _vm.config.disenio.datos.color_terciario,
        }),attrs:{"use-router":"","link-gen":_vm.linkGen,"number-of-pages":_vm.totalRows,"per-page":_vm.perPage,"first-number":"","last-number":"","align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]),_c('b-modal',{ref:"my-modal",attrs:{"id":"modal-libro","header-bg-variant":"dark","header-text-variant":"light","title-class":"text-truncate","size":"xl","body-class":"p-0","centered":"","hide-footer":true},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h6',[_vm._v(_vm._s(_vm.title))])]},proxy:true}])},[(_vm.libro)?_c('b-embed',{staticClass:"border",attrs:{"aspect":"4by3 ","type":"iframe","src":'https://pdf-viewer.fiscalia.gob.bo/?url=' + _vm.libro}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
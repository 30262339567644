<template>
  <div>
    <!--<PageHeader :titulo="titulo"/>-->
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> /
        <span class="active">Publicaciones</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Publicaciones</h3>
      </template>
    </titulo-seccion>
    <div id="content-page" class="container my-4">
      <div class="row">
        <div class="col-md-6">
          <input
            v-model="buscar"
            type="search"
            @keyup.enter="buscarLibro()"
            class="mb-2 form-control"
            placeholder="Buscar"
          />
        </div>
        <div class="gap-2 col-md-6 d-grid d-md-flex justify-content-md-end">
          <button
            type="button"
            v-b-toggle="'collapse-2'"
            class="mb-2 btn btn-fiscalia"
            :style="{
              '--background-color-btn': config.disenio.datos.color_primario,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-funnel"
              viewBox="0 0 16 16"
            >
              <path
                d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="my-4 row">
        <div class="col-md-4 d-none d-md-block">
          <b-card class="border">
            <b-form-group label="Categorías">
              <b-form-radio-group
                v-model="categoria_id"
                :state="state"
                :options="categoriaOpciones"
                :style="{
                  '--background-color-secondary':
                    config.disenio.datos.color_secundario,
                }"
                name="radios-stacked"
                plain
                stacked
              />
            </b-form-group>
            <b-form-group label="Tipo">
              <b-form-radio-group
                v-model="tipo_id"
                :options="tipoOpciones"
                name="radios-stacked1"
                plain
                :style="{
                  '--background-color-secondary':
                    config.disenio.datos.color_secundario,
                }"
                stacked
              />
            </b-form-group>
            <b-form-group label="Fecha">
              <b-form-radio-group
                v-model="fecha"
                :options="fechaOpciones"
                name="radios-stacked2"
                plain
                stacked
                :style="{
                  '--background-color-secondary':
                    config.disenio.datos.color_secundario,
                }"
              />
            </b-form-group>
          </b-card>
        </div>
        <div class="col-md-8 col-sm-12">
          <div>
            <b-collapse id="collapse-2">
              <b-card>
                <div class="row">
                  <div class="col-md-4">
                    <b-form-group label="Categorias">
                      <b-form-select
                        v-model="categoria_id"
                        :options="categoriaOpciones"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group label="Tipo">
                      <b-form-select
                        v-model="tipo_id"
                        :options="tipoOpciones"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group label="Fecha">
                      <b-form-select
                        v-model="fecha"
                        :options="fechaOpciones"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </b-card>
            </b-collapse>
          </div>
          <b-card no-body class="border">
            <div class="media-list media-bordered">
              <b-media v-for="(item, index) in items" :key="index">
                <template #aside>
                  <div class="border rounded box-imagen box-imagen1 cursor">
                    <!-- v-b-modal.modal-libro @click="selectLibro(item)" -->
                    <a :href="item.url" target="_blank">
                      <b-img-lazy
                        v-if="
                          item.miniatura != '' &&
                          item.miniatura != 'null' &&
                          item.miniatura
                        "
                        :src="item.miniatura"
                        center
                        fluid
                        thumbnail
                        class="rounded cursor"
                        alt="Card image cap"
                      />
                      <b-img-lazy
                        v-else
                        src="images/pdf.png"
                        center
                        fluid
                        thumbnail
                        class="rounded cursor"
                        alt="Card image cap"
                      />
                    </a>
                  </div>
                </template>
                <div>
                  <!-- v-b-modal.modal-libro @click="selectLibro(item)" -->
                  <h6 class="item-name">
                    {{ item.titulo }}
                  </h6>
                  <p class="mb-0 item-by">
                    <span class="text-muted">Autor: </span
                    ><span>{{ item.autor }}</span>
                  </p>
                  <p class="item-by">
                    <span class="text-muted">Publicado: </span
                    ><span>{{ item.publicado }}</span>
                  </p>
                  <b-card-text
                    class="item-descripcion"
                    v-html="item.descripcion"
                  >
                  </b-card-text>
                  <b-card-text class="mb-0 item-descripcion">
                    <span class="text-muted">Categoría: </span
                    >{{ item.categoria.nombre }}
                  </b-card-text>
                  <b-card-text class="mb-0 item-descripcion">
                    <span class="text-muted">Tipo: </span>{{ item.tipo.nombre }}
                  </b-card-text>
                  <b-card-text class="mb-0 item-descripcion">
                    <span class="text-muted">Etiquetas: </span>
                    <span
                      v-for="(itiqueta, index) in item.etiquetas"
                      :key="index"
                    >
                      #{{ itiqueta.nombre }}
                    </span>
                  </b-card-text>
                </div>
              </b-media>
            </div>
          </b-card>
        </div>
      </div>
      <div id="navegacion" class="mt-5 mb-5 text-center row">
        <b-pagination-nav
          use-router
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalRows"
          :per-page="perPage"
          first-number
          last-number
          align="center"
          class="my-0"
          :style="{
            '--background-color-secondary':
              config.disenio.datos.color_secundario,
            '--background-color-tertiary': config.disenio.datos.color_terciario,
          }"
        />
      </div>
    </div>
    <!-- modal video -->
    <b-modal
      id="modal-libro"
      ref="my-modal"
      header-bg-variant="dark"
      header-text-variant="light"
      title-class="text-truncate"
      size="xl"
      body-class="p-0"
      centered
      :hide-footer="true"
    >
      <template #modal-title>
        <h6>{{ title }}</h6>
      </template>
      <!-- 
                url="http://fcasua.contad.unam.mx/apuntes/interiores/docs/98/1/mate_bas.pdf"
                url="https://web-api.fiscalia.gob.bo/webfiles/biblioteca/informe-ministerio-publico-3-anos-de-gestion-digital_1664208764.pdf"
                :src="'https://pdf-viewer.fiscalia.gob.bo/?url='+libro"
             -->

      <!-- <MpV2GpdfViewer
                url="http://fcasua.contad.unam.mx/apuntes/interiores/docs/98/1/mate_bas.pdf"
                :download="false"
            /> -->

      <b-embed
        v-if="libro"
        class="border"
        aspect="4by3 "
        type="iframe"
        :src="'https://pdf-viewer.fiscalia.gob.bo/?url=' + libro"
      />
    </b-modal>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
//import PageHeader from '/src/components/PageHeader.vue';

import {
  BEmbed,
  BModal,
  VBModal,
  BMedia,
  BCard,
  BFormRadioGroup,
  BFormGroup,
  BCollapse,
  BFormSelect,
  BPaginationNav,
} from "bootstrap-vue";
import TituloSeccion from "@/views/secciones/TituloSeccion.vue";
// import MpV2GpdfViewer from 'mp-v2-gpdf-viewer'

export default {
  components: {
    //PageHeader,
    // MpV2GpdfViewer,
    BModal,
    BEmbed,
    BMedia,
    BCard,
    BFormRadioGroup,
    BFormGroup,
    BCollapse,
    BFormSelect,
    TituloSeccion,
    BPaginationNav,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      titulo: "Biblioteca",
      items: [],
      libro: "",
      buscar: "",
      categoria_id: "",
      categoriaOpciones: [
        {
          text: "Todos",
          value: "",
        },
      ],
      tipoOpciones: [
        {
          text: "Todos",
          value: "",
        },
      ],
      tipo_id: "",
      etiquetas_id: [],
      fecha: "",
      fechaOpciones: [
        {
          text: "Todos",
          value: "",
        },
      ],
      title: "",
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
    };
  },
  metaInfo: {
    title: "Ministerio Público",
    titleTemplate: "%s | Biblioteca",
    htmlAttrs: {
      lang: "es-ES",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Biblioteca Institucional se constituye en la forma más apropiada para proporcionar acceso abierto a texto completo",
      },
    ],
  },
  created() {
    if (Object.keys(this.$route.query).length === 0) {
      this.currentPage = 1;
      this.listarLibros();
    } else {
      this.currentPage = this.$route.query.page;
      this.listarLibros();
    }
    this.listarCategorias();
    this.listarTipoLibros();
    this.listarFecha();
  },
  computed: {
    state() {
      return true;
    },
    config() {
      return this.$store.state.config;
    },
  },
  watch: {
    buscar: function (val) {
      if (val == "" || val.length == 0) {
        if (this.$route.fullPath == "/biblioteca") {
          this.listarLibros();
        } else {
          this.$router.push({ name: "biblioteca" });
        }
      }
    },
    categoria_id: function () {
      if (this.$route.fullPath == "/biblioteca") {
        this.listarLibros();
      } else {
        this.$router.push({ name: "biblioteca" });
      }
      //this.listarLibros();
    },
    tipo_id: function () {
      if (this.$route.fullPath == "/biblioteca") {
        this.listarLibros();
      } else {
        this.$router.push({ name: "biblioteca" });
      }
      //this.listarLibros();
    },
    etiquetas_id: function () {
      if (this.$route.fullPath == "/biblioteca") {
        this.listarLibros();
      } else {
        this.$router.push({ name: "biblioteca" });
      }
      //this.listarLibros();
    },
    fecha: function () {
      if (this.$route.fullPath == "/biblioteca") {
        this.listarLibros();
      } else {
        this.$router.push({ name: "biblioteca" });
      }
      //this.listarLibros();
    },
    $route: function () {
      if (Object.keys(this.$route.query).length === 0) {
        this.currentPage = 1;
        this.listarLibros();
      } else {
        this.currentPage = this.$route.query.page;
        this.listarLibros();
      }
    },
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    listarLibros() {
      var url =
        "/admin/libros?page=" +
        this.currentPage +
        "&categoria=" +
        this.categoria_id +
        "&tipo=" +
        this.tipo_id +
        "&buscar=" +
        this.buscar +
        "&etiquetas=" +
        this.etiquetas_id +
        "&fecha=" +
        this.fecha;
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data;
          this.totalRows = res.data.last_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectLibro(data) {
      this.title = data.titulo;
      // this.libro=data.url;

      // this.keyLibro = Date().getTime()
      this.libro = encodeURIComponent(data.url);
    },
    listarCategorias() {
      var url = "/admin/categoria-libros";
      axiosIns
        .get(url)
        .then((res) => {
          const posts = res.data.map((g) => ({ text: g.nombre, value: g.id }));
          this.categoriaOpciones = this.categoriaOpciones.concat(posts);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listarTipoLibros() {
      var url = "/admin/tipo-libros";
      axiosIns
        .get(url)
        .then((res) => {
          const posts = res.data.map((g) => ({ text: g.nombre, value: g.id }));
          this.tipoOpciones = this.tipoOpciones.concat(posts);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listarFecha() {
      var url = "/admin/libros-fecha";
      axiosIns
        .get(url)
        .then((res) => {
          const posts = res.data.map((g) => ({ text: g.year, value: g.year }));
          this.fechaOpciones = this.fechaOpciones.concat(posts);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buscarLibro() {
      if (this.$route.fullPath == "/biblioteca") {
        this.listarLibros();
      } else {
        this.$router.push({ name: "biblioteca" });
      }
    },
    selectCategoria(val) {
      console.log(val);
    },
  },
};
</script>

<style>
.cursor {
  cursor: pointer;
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-aside {
  display: flex;
  margin-right: 1rem;
}
.align-self-start {
  align-self: flex-start !important;
}
.media-list .media {
  padding: 1.25rem;
  margin-top: 0;
}
.media-bordered .media:not(:first-child) {
  border-top: 1px solid #ebe9f1;
}
.card-title {
  font-size: 14px;
}
.item-name {
  font-weight: 600;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.item-by {
  font-weight: 400;
  font-size: 0.875rem;
}
/* .item-descripcion {
    -webkit-box-ordinal-group: 4;
    order: 3;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
} */
.box-imagen {
  height: 15rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.embed-responsive-4by3 {
  height: calc(var(--vh, 1vh) * 100 - 10rem) !important;
}
@media (max-width: 700px) {
  .box-imagen {
    height: 5rem;
  }
  .embed-responsive-4by3 {
    height: calc(var(--vh, 1vh) * 100 - 10rem) !important;
  }
}
</style>
